export const WORKSPACE_FOLDERS = {
  FLOWS: "flows",
  VIRTUAL_STEP: "virtual-steps",
  SCHEDULES: "schedules",
  ERROR_HANDLERS: "error-handlers",
  VARIABLES: "variables",
  APIS: "apis",
  PACKAGES: "packages",
} as const;

export const APIS_FOLDERS = {
  SCHEMAS: "schemas",
  FIELDS: "fields",
  HEADERS: "headers",
  PARAMETERS: "parameters",
  RESPONSES: "responses",
} as const;

export const WORKSPACE_PATHS = {
  FLOWS: WORKSPACE_FOLDERS.FLOWS,
  VIRTUAL_STEPS: WORKSPACE_FOLDERS.VIRTUAL_STEP,
  APIS: WORKSPACE_FOLDERS.APIS,
  SCHEDULES: WORKSPACE_FOLDERS.SCHEDULES,
  ERROR_HANDLERS: WORKSPACE_FOLDERS.ERROR_HANDLERS,
  VARIABLES: WORKSPACE_FOLDERS.VARIABLES,
  API_SCHEMAS: `${WORKSPACE_FOLDERS.APIS}/${APIS_FOLDERS.SCHEMAS}`,
  API_FIELDS: `${WORKSPACE_FOLDERS.APIS}/${APIS_FOLDERS.FIELDS}`,
  API_HEADERS: `${WORKSPACE_FOLDERS.APIS}/${APIS_FOLDERS.HEADERS}`,
  API_PARAMETERS: `${WORKSPACE_FOLDERS.APIS}/${APIS_FOLDERS.PARAMETERS}`,
  API_RESPONSES: `${WORKSPACE_FOLDERS.APIS}/${APIS_FOLDERS.RESPONSES}`,
  PACKAGES: WORKSPACE_FOLDERS.PACKAGES,
} as const;

export const ALL_WORKSPACE_PATHS = Object.values(WORKSPACE_PATHS);

const ALL_WORKSPACE_PATHS_BY_SEPERATORS = ALL_WORKSPACE_PATHS.sort((a, b) => {
  const aLength = a.split("/").length;
  const bLength = b.split("/").length;
  if (aLength === bLength) return 0;
  return aLength > bLength ? 1 : -1;
});

export const startsWithWorkspacePath = (
  entityPath: string
): string | undefined => {
  for (let i = ALL_WORKSPACE_PATHS_BY_SEPERATORS.length - 1; i >= 0; i--) {
    const test = ALL_WORKSPACE_PATHS_BY_SEPERATORS[i];

    if (entityPath.startsWith(test)) {
      return test;
    }
  }
};
