import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const retryStepSchema: StepSchema = {
  stepType: Steps.RETRY,
  color: "rgb(189,208,196)",
  jsonSchema: {
    description: "Retry step definition",
    title: "Retry",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        description:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        title: "Condition",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["successCondition", "flowId", "targetPath"],
        properties: {
          flowId: {
            type: "string",
            title: "Flow",
            description: "Flow to execute",
          },
          payloadPath: {
            type: "string",
            title: "Flow payload path (doc - by default)",
          },
          targetPath: {
            type: "string",
            title: "Target path",
            description: "Define the path in which to store the flow result",
          },
          delayBetweenExecutions: {
            type: "number",
            title: "Delay between executions (s)",
          },
          maxRetries: {
            type: "number",
            title: "Max number of retries",
          },
          maxRetryDuration: {
            type: "number",
            title: "Max retry duration (s)",
          },
          successCondition: {
            type: "string",
            title: "Success condition",
            pattern: REGX_FOR_VALID_STRING_EXPRESSION,
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      flowId: {
        "ui:field": "flowSearch",
      },
    },
  },
};
