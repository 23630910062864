import { SettingsManager } from "@sapiens-digital/ace-designer-app/app/services/settingsManager";

import { getTimeRemainingToExpire } from "./JWTTokenUtils";

export const closeWindowAfterRemovingAuthDetails = (): void => {
  clearAuthDetailsFromLocalStore();
  window.close();
};

export const getTokenFromLocalStorage = (): string | null =>
  localStorage.getItem("Authorization");

/**
 * Only retrieve the token if it is not expired.
 */
export const getUnexpiredTokenFromLocalStorage = (): string | null => {
  const token = getTokenFromLocalStorage();
  if (!token) {
    return null;
  }

  const ttl = getTimeRemainingToExpire(token);
  if (!ttl || ttl < 0) {
    return null;
  }

  return token;
};

export const getUserFromLocalStorage = (): string | null =>
  localStorage.getItem("User");

export const clearAuthDetailsFromLocalStore = (): void => {
  localStorage.removeItem("Authorization");
  localStorage.removeItem("User");
};

export const isLogoutAction = (): boolean =>
  new URLSearchParams(window.location.search).get("action") === "logout";

export const getIdpAuthUrl = (): string => {
  return `/auth/${SettingsManager.getDesignerConfigs().authStrategy}`;
};

export const isAuthenticationEnabled = (): boolean => {
  return Boolean(SettingsManager.getDesignerConfigs().authStrategy);
};
