import { useCallback, useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "@sapiens-digital/ace-designer-app/app/store";
import { addNotification } from "@sapiens-digital/ace-designer-app/app/store/designer/actions";
import { selectIsDesignerLoading } from "@sapiens-digital/ace-designer-app/app/store/designer/selectors";
import { saveGitAuthorFromKeycloakAction } from "@sapiens-digital/ace-designer-app/app/store/settings/actions";
import { getAuthorizationUser } from "@sapiens-digital/ace-designer-app/app/store/utils/getAuthorization";
import { AUTH_ERROR } from "@sapiens-digital/ace-designer-common";

import { getTimeRemainingToExpire } from "./JWTTokenUtils";
import {
  clearAuthDetailsFromLocalStore,
  getUnexpiredTokenFromLocalStorage,
  isAuthenticationEnabled,
} from "./utils";

/**
 * Hook to get the Auth token from local storage and listen for token changes
 */
export const useToken = (): {
  token: string | null;
  clearToken: () => void;
} => {
  const [token, setToken] = useState(getUnexpiredTokenFromLocalStorage());

  const [expTimeoutRef, setExpTimeoutRef] =
    useState<ReturnType<typeof setTimeout>>();
  const isDesignerLoading = useAppSelector(selectIsDesignerLoading);
  const dispatch = useAppDispatch();

  const clearToken = useCallback(
    (isTokenInvalidOrExpired = false) => {
      setToken(null);
      setExpTimeoutRef(undefined);
      clearAuthDetailsFromLocalStore();
      if (isTokenInvalidOrExpired) {
        dispatch(
          addNotification({
            message: "Session has expired",
            variant: "error",
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      if (expTimeoutRef) {
        clearTimeout(expTimeoutRef);
      }
    };
  }, [expTimeoutRef]);

  const registerTimeoutForTokenExp = useCallback(
    (token: string) => {
      if (isAuthenticationEnabled() && !expTimeoutRef) {
        const ttl = getTimeRemainingToExpire(token);

        if (!ttl || ttl < 0) {
          return clearToken();
        }

        const ref = setTimeout(clearToken, ttl);
        setExpTimeoutRef(ref);
      }
    },
    [clearToken, expTimeoutRef]
  );

  useEffect(() => {
    if (!token) {
      return;
    }

    registerTimeoutForTokenExp(token);

    const user = getAuthorizationUser();
    if (!isDesignerLoading) {
      dispatch(
        saveGitAuthorFromKeycloakAction({
          name: user.name ?? null,
          email: user.email ?? null,
        })
      );
    }
  }, [dispatch, isDesignerLoading, registerTimeoutForTokenExp, token]);

  const onStorageChange = useCallback(
    (event: StorageEvent): void => {
      if (event.key === AUTH_ERROR && localStorage.getItem(AUTH_ERROR)) {
        dispatch(
          addNotification({
            message: localStorage.getItem(AUTH_ERROR) as string,
            variant: "error",
          })
        );
        localStorage.removeItem(AUTH_ERROR);
        return;
      }

      if (event.key !== "Authorization") {
        return;
      }

      if (!event.newValue) {
        return clearToken();
      }

      const ttl = getTimeRemainingToExpire(event.newValue);

      if (!ttl || ttl < 0) {
        return clearToken(true);
      }

      setToken(event.newValue);
    },
    [clearToken, dispatch]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageChange);
    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, [onStorageChange]);

  return { token, clearToken };
};
