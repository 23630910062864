import React from "react";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useAppSelector } from "../store";

const Root = styled("div")(() => ({
  display: "flex",
  height: "20px",
}));

const Version: React.FC = () => {
  const version = useAppSelector((state) => state.designer.version);

  return (
    <Root>
      <ErrorOutlineSharpIcon
        sx={{ height: "16px", width: "16px", margin: "auto" }}
      />
      <Typography sx={{ fontSize: "12px", margin: "auto", paddingLeft: "5px" }}>
        ACE {version}
      </Typography>
    </Root>
  );
};

export default Version;
