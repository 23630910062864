export const getACEVersion = async (): Promise<string> => {
  const response = await fetch("VERSION");
  let result = await response.text();
  result = result.trim();
  const regex = new RegExp("^(\\d+).(\\d+).(.+)");

  if (regex.test(result)) {
    result = `${result}`;

    return result;
  } else {
    result = "development";

    return result;
  }
};
