import { Logger } from "../logging";
import { SchemaRepository } from "../repositories";
import { SchemaObject } from "./ReferenceResolution";

export class BaseReferenceResolution {
  private logger = Logger.getLogger("ReferenceResolution");

  constructor(private schemaRepository: SchemaRepository) {}

  protected async lookupSchema(
    schemaName: string,
    usagePath: string
  ): Promise<SchemaObject | undefined> {
    try {
      return await this.schemaRepository.findSchema(schemaName, usagePath);
    } catch (e) {
      this.logger.warn(`Could not resolve schema "${schemaName}"`, e);
    }
  }
}
