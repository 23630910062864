import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AppEventName,
  UserSettings,
  UserSettingsStatus,
} from "@sapiens-digital/ace-designer-common";

import { emitAppEvent } from "../../services/appEvents";
import {
  loadDesignerSettings,
  saveDesignerSettings,
  saveDesignerWorkspaceSettings,
} from "../../services/settings";
import { SettingsManager } from "../../services/settingsManager";
import {
  hideLocalRepositoryWarning,
  initializeWorkspacesThunk,
  showLocalRepositoryWarning,
} from "../designer/actions";
import { resetDesignerPages } from "../designer/reducers";
import { RootState } from "../index";
import { areSettingsValid } from "../utils/areSettingsValid";
import { assertWorkspace } from "../utils/assertEntities";
import {
  deleteLocalWorkspacesAction,
  updateAndSetupWorkspaceAction,
} from "../workspaces/actions";
import {
  selectSelectedWorkspace,
  workspaceSelectors,
} from "../workspaces/selectors";

export const loadDesignerSettingsAction = createAsyncThunk<
  UserSettings,
  void,
  { state: RootState }
>("settings/load", async (_, { dispatch }) => {
  const newSettings = await loadDesignerSettings();
  return await dispatch(applyUserSettingsAction(newSettings)).unwrap();
});

export const applyUserSettingsAction = createAsyncThunk<
  UserSettings,
  UserSettingsStatus,
  { state: RootState }
>("settings/apply", async (settings, { dispatch, getState }) => {
  const existingSettings = getState().settings;
  let newSettings: UserSettings;

  if (settings.status === "ok") {
    newSettings = settings.value;
  } else {
    newSettings = { ...settings.defaultValue, ...existingSettings };
  }

  SettingsManager.setDesignerSettings(newSettings);
  dispatch(updateSettingsAction(newSettings));

  // If unauthorized, we don't want to show repository warning
  if (settings.status !== "unauthorized") {
    if (areSettingsValid(newSettings)) {
      dispatch(hideLocalRepositoryWarning());
    } else {
      dispatch(showLocalRepositoryWarning());
    }
  }

  return newSettings;
});

export const detectRepositoryChangeThunk = createAsyncThunk<
  void,
  UserSettings,
  { state: RootState }
>(
  "settings/detectRepositoryChange",
  async (oldSettings, { dispatch, getState }) => {
    const { settings } = getState();

    if (oldSettings.repositoryUrl !== settings.repositoryUrl) {
      await dispatch(deleteLocalWorkspacesAction()).unwrap();
      await dispatch(initializeWorkspacesThunk(settings)).unwrap();
    } else if (
      oldSettings.repositoryWorkspacePath !== settings.repositoryWorkspacePath
    ) {
      await dispatch(initializeWorkspacesThunk(settings)).unwrap();
    }
  }
);

export const saveDesignerSettingsThunk = createAsyncThunk<
  void,
  UserSettings,
  { state: RootState }
>(
  "settings/save",
  async (settings: UserSettings, { getState, dispatch, rejectWithValue }) => {
    const error = await saveDesignerSettings(settings);

    if (error) {
      return rejectWithValue(error);
    }

    const newSettings = await dispatch(
      applyUserSettingsAction({ value: settings, status: "ok" })
    ).unwrap();

    const currentWorkspace = selectSelectedWorkspace(getState());
    assertWorkspace(currentWorkspace);

    emitAppEvent(AppEventName.UpdatedWorkspaceSettings, {
      branch: currentWorkspace.name,
      workspacePath: newSettings.repositoryWorkspacePath,
    });
  }
);

export const saveGitAuthorFromKeycloakAction = createAsyncThunk<
  UserSettings,
  { name: string | null; email: string | null },
  { state: RootState }
>(
  "settings/saveGitAuthorFromKeycloak",
  async ({ name, email }, { getState }) => {
    const { settings } = getState();
    const newState: UserSettings = {
      ...settings,
      isNameEmailFromKeycloak: true,
    };

    if (name) {
      newState.fullName = name;
    }

    if (email) {
      newState.email = email;
    }

    SettingsManager.setGitKeycloakAuthor({
      name: newState.fullName,
      email: newState.email,
    });

    return newState;
  }
);

const removeKeycloakNameEmail = async (settings: UserSettings) => {
  const settingsFromFile = await loadDesignerSettings();

  if (settingsFromFile.status !== "ok") {
    throw new Error("Could not load settings");
  }

  const newState = {
    ...settings,
    fullName: settingsFromFile.value.fullName,
    email: settingsFromFile.value.email,
    isNameEmailFromKeycloak: false,
  };

  return newState;
};

/**
 * This action resets the parts of the settings that are filled with Keycloak information (e.g., name, email).
 */
export const removeGitAuthorFromKeycloakAction = createAsyncThunk<
  UserSettings,
  void,
  { state: RootState }
>("settings/removeGitAuthorFromKeycloakAction", async (_, { getState }) => {
  SettingsManager.resetGitKeycloakAuthor();
  const { settings } = getState();
  return removeKeycloakNameEmail(settings);
});

export const switchWorkspaceAction = createAsyncThunk(
  "settings/switchWorkspace",
  async (workspaceId: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const settings = { ...state.settings, selectedWorkspaceId: workspaceId };

    const workspace = workspaceSelectors.selectById(state, workspaceId);
    assertWorkspace(workspace, workspaceId);

    dispatch(selectWorkspaceAction(workspaceId));
    dispatch(resetDesignerPages());

    await saveDesignerWorkspaceSettings(settings);

    if (areSettingsValid(settings)) {
      await dispatch(
        updateAndSetupWorkspaceAction({
          ...settings,
          workspaceName: workspace.name,
        })
      ).unwrap();
    }

    emitAppEvent(AppEventName.UpdatedWorkspaceSettings, {
      branch: workspace.name,
      workspacePath: workspace.repositoryWorkspacePath,
    });
  }
);

export const selectWorkspaceAction = createAction<string | undefined>(
  "settings/selectWorkspace"
);

export const updateSettingsAction = createAction<UserSettings>(
  "settings/updateSettings"
);
