import { JWTToken } from "@sapiens-digital/ace-designer-server-common/lib/model";
import { jwtDecode } from "jwt-decode";

export const getTimeRemainingToExpire = (
  newToken: string | null
): number | undefined => {
  if (!newToken) {
    return;
  }

  const exp = extractJWTData(newToken)?.exp;
  if (exp) {
    return exp * 1000 - Date.now();
  }
};

const extractJWTData = (newToken: string): JWTToken | null => {
  try {
    return jwtDecode<JWTToken>(newToken);
  } catch (e) {
    return null;
  }
};
