export type UserSettings = UserDesignerSettings &
  UserAuthorSettings &
  Omit<UserRepositorySettings, "repositoryUrl"> &
  UserWorkspaceSettings & {
    repositoryUrl?: string;
  };

export type UserSettingsStatus =
  | {
      status: "ok";
      value: UserSettings;
    }
  | {
      status: "unauthorized";
      defaultValue: UserSettings;
    };

export type UserAuthorSettings = {
  fullName: string;
  email: string;
  isNameEmailFromKeycloak: boolean;
};

export type UserDesignerSettings = {
  runtimeServerPort?: number;
  workspacesLocation: string;
};

export type UserWorkspaceSettings = {
  selectedWorkspaceId?: string;
};

export type UserRepositorySettings = {
  repositoryUrl: string;
  repositoryUsername?: string;
  repositoryWorkspacePath: string;
  repositoryToken?: string;
  repositoryDefaultBranch: string;
};

export type ValidatedSettings = UserSettings &
  Required<Pick<UserSettings, "repositoryUrl" | "repositoryToken">>;

export enum RepositoryAccessFailure {
  Unauthorized = "unauthorized",
  NotFound = "notfound",
  NotFoundBranch = "branchnotfound",
  Unknown = "unknown",
}
